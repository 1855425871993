<template>
  <div>
    <CTableWrapper ref="table" hover striped :caption="caption" :items="items" :actions="actions" :fields="fields" clickable-rows @row-clicked="rowClicked" @action-clicked="actionClicked" @update-requested="updateItemsRequested" :loading="loading">
    </CTableWrapper>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import CTableWrapper from '../controls/PaginatedTable';
import router from '@/router';
import InputGetUsers from "@/classes/InputGetUsers";

export default {
  name: 'Dashboard',
  components: { CTableWrapper },
  data () {
    return {
      caption: ('Users'),
      itemsPerPage: localStorage.itemsPerPage ?? 10,
      fields: [
        { key: 'firstName', label: ('FirstName'), _classes: 'font-weight-bold', sortable: false },
        { key: 'lastName', label: ('LastName'), _classes: 'font-weight-bold', sortable: false },
        { key: 'email', label: ('Email'), _classes: 'font-weight-bold', sortable: false },
        { key: 'phoneNumber', label: ('PhoneNumber'), _classes: 'font-weight-bold', sortable: false },
      ],
      actions: [
        { key: 'view', label: ('view') },
      ],
      loading: false
    }
  },
  computed: {
    ...mapState('users', ['listOutput']),
    usersInfo: function() {
      return this.listOutput ? this.listOutput : null;
    },
    items: function()
    {
      return this.usersInfo ? this.usersInfo : [];
    },
  },
  mounted: function()
  {
    this.updateItemsRequested();
  },
  methods: {
    ...mapActions('users', ['getList']),
    rowClicked (item, index) {
      this.$router.push({name: "EditUsers", params: { user: item } });
    },
    actionClicked(item, action){
      switch(action.key)
      {
        case 'view':
        {
          this.$router.push({name: "EditUsers", params: { user: item } });
          break;
        }
        default:
        {
          throw "The provided action is not supported!"; 
        }
      }
    },
    updateItemsRequested()
    {
      //Make request
      this.loading = true;

      let input = new InputGetUsers();
      this.getList(input); 
    },
    addSponsor () 
    {
      router.push({ path: '/sponsors/add' });
    },
  },
  watch:
  {
    listOutput: function(val)
    {
      if(val){
        this.loading = false;
      }
    }
  }
}
</script>

<style>
th
{
  border-top: none !important;
}

tr
{
  cursor: pointer;
}

.status-button
{
  border: none;
  padding: 0;
  text-decoration: underline;
  color: #321fdb;
}
</style> 